<template>
  <el-card shadow="never" style="height: 100%">
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
    >
      <el-form-item label="类别ID" prop="id" v-if="false">
        <el-input
            v-model="ruleForm.id"
            autocomplete="off"
            :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="类别名称" prop="name">
        <el-input v-model="ruleForm.name" autocomplete="off" @blur="checkTypeName"></el-input>
      </el-form-item>
      <el-form-item label="父类别名称" prop="parentId">
        <el-select v-model="bigTypeId" placeholder="请选择">
          <el-option
              v-for="item in bigOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="editTypeMid('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
       rules: {
        name: [
          {required: true, message: "请输入类别名称", trigger: "blur"},
        ],
        parentId: [
          {required: true, message: "请选择类别", trigger: "blur"},
        ],
      },
      bigTypeId: 0,
      // value:'',
      ruleForm: {
        name: "",
        id: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      bigOptions: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkTypeName() {
      this.$api.get(
          "/type/checkTypeName",
          null,
          {id: this.ruleForm.id, parentId: this.bigTypeId, name: this.ruleForm.name},
          (successRes) => {
            console.log(successRes);
            if (!successRes.data) {
              this.$message("类别名称已存在!")
            }
          },
          (failureRes) => {
            this.$message("服务出错!")
          }
      );
    },
    getBigTypeList() {
      this.$api.get(
          "/type/big/list",
          null,
          {
            list:false
          },
          (successRes) => {
            console.log(successRes.data.rows);
            this.bigOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getUserInfoById(id) {
      this.$api.get(
          "/type/mid/getById",
          null,
          {id: id},
          (successRes) => {
            console.log(successRes);
            this.ruleForm = successRes.data;
            this.bigTypeId = successRes.data.parentId;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    init() {
      const midTypeId = this.$route.query.id;
      this.getUserInfoById(midTypeId);
      this.getBigTypeList();
    },
     editTypeMid(ruleForm) {
      // console.log(this.$refs[ruleForm]);
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
          "/type/mid/update",
          null,
          {
            name: this.ruleForm.name,
            id: this.ruleForm.id,
            parentId: this.bigTypeId,
          },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              this.$router.push({name: "mmasterList"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
          
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    },
  },
};
</script>